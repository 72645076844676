import countries from "./db/countries"
import env from '../env.js'
import { getUserData } from '@/auth/utils'

export default {
    activeUser: getUserData(),
    layout: {
        loader: false,
        windowWidth: window.innerWidth,
    },
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    windowWidth: null,
    country: {},
    countries: countries,
    product:{},
    project:{},
}
