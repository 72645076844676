export default [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      redirect: '/',
      component: () => import('@/layouts/Main.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@views/Dashboard.vue'),
          meta: {
            breadcrumb: [
              {
                text: "Dashboard",
                active: true
              }
            ],
            title: 'Dasbboard',
            authRequired: true,
          }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@views/account/UserEdit.vue'),
          meta: {
              breadcrumb: [
                {
                  text: "Dashboard",
                  to:{path:"/"}
                },
                {
                  text: "Account",
                  active: true
                }
            ],
            title: 'Profile',
            authRequired: true,
          },
        },
        {
          path: '/orders',
          name: 'order',
          component: () => import('@views/orders/OrderList.vue'),
          meta: {
            breadcrumb: [
                {
                  text: "Dashboard",
                  to:{path:"/"}
                },
                {
                  text: "Orders",
                  active: true
                }
            ],
            title: "Orders List",
            authRequired: true,
          },
        },
        {
          path: '/orders/:orderId(\\d+)',
          name: 'order-view',
          component: () => import('@views/orders/OrderShow.vue'),
          meta: {
              breadcrumb: [
                {
                  text: "Orders",
                  to:{path:"/orders"}
                },
                {
                  text: "Show",
                  active: true
                }
            ],
            title: "View Order",
            authRequired: true,
          },
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('@views/notifications/NotificationList.vue'),
          meta: {
              breadcrumb: [
                {
                  text: "Dashboard",
                  to:{path:"/"}
                },
                {
                  text: "Notifications List",
                  active: true
                }
            ],
            title: "Notifications",
            authRequired: true,
          }
        },
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/FullPage.vue'),
      children: [          
        {
          path: '/error-404',
          name: 'error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta:{
            title: "404 | Page Not Found"
          }
        },
        {
          path: '/error-500',
          name: 'error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta:{
            title: "500 | Server Error"
          }
        },
        {
          path: '/auth',
          name: 'auth',
          component: () => import('@/views/pages/Authenticate.vue'),
          meta:{
            title: "Auto Login"
          }
        },
      ]
    },
  // Redirect to 404 page, if no match found
  {
    path: '*',
    redirect: '/error-404'
  }
]